import { signIn, useSession } from "next-auth/react";
import { Loader } from "@progress/kendo-react-indicators";
import { useRouter } from "next/router";
import { useEffect } from "react";

function Auth({ children }) {
  const router = useRouter();
  const { data: session, status } = useSession();
  const isLoading = status === "loading";

  const isUser = !!session?.user;
  const loggedOut = router.asPath === "/logged_out";
  useEffect(() => {
    if (isLoading || loggedOut) {
      return;
    }
    if (!isUser) signIn("okta");
  }, [loggedOut, isUser, isLoading]);

  if (loggedOut || isUser) {
    return children;
  }

  // Session is being fetched, or no user.
  // If user is not logged in, useEffect() will redirect.
  return (
    <div>
      {/* https://thrasio.atlassian.net/browse/RD-983 */}
      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
      <div style={{ marginTop: "15px", textAlign: "center" }}>
        <span className="k-d-inline-flex k-flex-col k-align-items-center">
          <Loader />
        </span>
      </div>
    </div>
  );
}

export default Auth;
