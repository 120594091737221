import { FieldPolicy, InMemoryCache } from "@apollo/client";

/*
  This function creates the cache that Apollo will use to retrieve and store data from the useQuery hook
  You should add to this config if you need Apollo to handle your cached results in a non default way
*/
export function buildApolloClientCache(): InMemoryCache {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          productListing: CombinePaginatedResultsFieldPolicy,
        },
      },
    },
  });
}

/*
  Use this policy for your query in the cache if it returns paginated data to utilize the fetchMore apollo function from 
  the useQuery hook to handle fetching and merging results of each page into one combined list of data for each page fetched
*/
const CombinePaginatedResultsFieldPolicy: FieldPolicy = {
  keyArgs: ["filter", "sort"],
  merge(existing, incoming) {
    if (!existing) {
      return incoming;
    } else {
      const combined = { ...existing };
      combined.results = [...existing.results, ...incoming.results];
      return combined;
    }
  },
};
