import { datadogLogs } from "@datadog/browser-logs";

export default function initDatadogClient() {
  if (
    process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true" &&
    typeof window !== "undefined"
  ) {
    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      site: "datadoghq.com",
      env: window.location.hostname,
      version: process.env.NEXT_PUBLIC_VERSION,
      service: "tmi-ui",
    });
    datadogLogs.logger.setLevel("info");
  }
}
