import isBoolean from "lodash/isBoolean";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import get from "lodash/get";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import pick from "lodash/pick";
import compact from "lodash/compact";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import join from "lodash/join";
import cloneDeep from "lodash/cloneDeep";
import invert from "lodash/invert";
import groupBy from "lodash/groupBy";

export {
  isBoolean,
  isString,
  isArray,
  get,
  pick,
  compact,
  omit,
  uniq,
  isEqual,
  cloneDeep,
  invert,
  groupBy,
};

export const isDefinedAndNonNull = (item: any): boolean =>
  item !== undefined && item !== null;

export const isNonEmptyArray = (item: any): boolean =>
  isArray(item) && item.length > 0;

//Removes objects from list based on duplicate value for specified key,
//removing all duplicates but the last one found in order of the list
export const removeDuplicatesByKey = (
  items: Record<string, any>[],
  byKey: string
) => {
  return Array.from(new Map(items.map((item) => [item[byKey], item])).values());
};

export const isNonEmptyString = (value) =>
  isDefinedAndNonNull(value) && typeof value === "string" && value.length > 0;

export const isObjectLike = (item) => item != null && typeof item == "object";

export function keyBy<T>(array: T[], key: keyof T) {
  return array.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {} as any);
}
/**
 * Converts a typical map to array of name text obj
 * Example input map: {A: "a", B: "b"}
 * Example output array: [{name: A, text: "a"}, {name: B, text: "b"}]
 */
export const mapToNameValueArray = <T>(map: {
  [s: string]: T;
}): { name: string; text: T }[] => {
  return Array.from(Object.entries(map), ([name, text]) => ({ name, text }));
};

export const booleanToYesNo = (value: boolean) => (value ? "Yes" : "No");
export const titleCase = (value: string) => startCase(toLower(value));

export const getFirst = <T>(maybeArray: T | T[]): T => {
  if (Array.isArray(maybeArray)) {
    return maybeArray.length > 0 ? maybeArray[0] : null;
  }
  return maybeArray;
};

export const numberToFormattedString = (number = 0) => {
  number = Number(number);
  if (Number.isInteger(number)) {
    return join(
      Number(number)
        .toString()
        .match(/(\d+?)(?=(\d{3})+(?!\d)|$)/g),
      ","
    );
  }

  return number
    .toFixed(2)
    .toString()
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const getTextForList = (
  list: any[],
  delimiter: string = ",",
  emptyText: string = "None"
) => {
  return isDefinedAndNonNull(list) && list.length > 0
    ? list.join(delimiter)
    : emptyText;
};

export const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate.getTime());
  const dates = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

// source: https://stackoverflow.com/a/43055893
export const getCombinations = (variants) => {
  return (function recurse(keys) {
    if (!keys.length) return [{}];
    let result = recurse(keys.slice(1));
    return variants[keys[0]].reduce(
      (acc, value) =>
        acc.concat(
          result.map((item) => Object.assign({}, item, { [keys[0]]: value }))
        ),
      []
    );
  })(Object.keys(variants));
};

export const removeField = (objectsArray, fieldToRemove) => {
  return objectsArray.map((obj) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [fieldToRemove]: _, ...rest } = obj;
    return rest;
  });
};
