import TagManager from "react-gtm-module";
import { getSession } from "next-auth/react";
import { BaseRouter } from "next/dist/shared/lib/router/router";
import { getUserDepartment } from "@/components/util/session-utils";

export default function initAnalytics() {
  if (
    process.env.NEXT_PUBLIC_GTM_ENABLED === "true" &&
    process.env.NEXT_PUBLIC_GTM_ID &&
    typeof window !== "undefined"
  ) {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      dataLayer: {},
    };

    TagManager.initialize(tagManagerArgs);

    getSession().then((session) => {
      if (session) {
        const { user } = session;

        TagManager.dataLayer({
          dataLayer: {
            userId: user.id,
            email: user.email,
            displayName: user.name,
            department: getUserDepartment(user.groups),
          },
        });
      }
    });
  }
}

const reportEvent = (args: {
  element?: Element;
  action: string;
  pathname: string;
  page: string;
  event: string;
}) => {
  const dataLayer = {
    appName: "thrasioToolsWebApp",
    elementName: args.element,
    actionType: args.action,
    event: args.event,
    page: args.page,
  };

  TagManager.dataLayer({ dataLayer });
};

export const reportPageview = (router: BaseRouter, url: string) => {
  reportEvent({
    action: "pageview",
    event: "pageview",
    pathname: router.pathname,
    page: url,
  });
};
