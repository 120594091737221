import * as React from "react";

import "./app.css";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "flag-icon-css/css/flag-icon.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { SessionProvider } from "next-auth/react";

import { ApolloProvider } from "@apollo/client";
import { useEffect } from "react";
import { useRouter } from "next/router";
import App from "next/app";
import { useApollo } from "@/platform/apolloClient";
import PageContainer from "@/platform/PageContainer";
import initDatadogClient from "@/platform/datadogClient";
import initAnalytics, { reportPageview } from "@/platform/Analytics";

library.add(fas, fab);

initDatadogClient();
initAnalytics();

const AppLayout = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);

  const router = useRouter();
  useEffect(() => {
    // Report the initial pageview
    reportPageview(router, router.pathname);

    const handler = (url) => {
      reportPageview(router, url);
    };
    router.events.on("routeChangeComplete", handler);
    return () => {
      router.events.off("routeChangeComplete", handler);
    };
  }, [router]);

  return (
    <SessionProvider session={pageProps.session}>
      <ApolloProvider client={apolloClient}>
        <PageContainer>
          <Component {...pageProps} />
        </PageContainer>
      </ApolloProvider>
    </SessionProvider>
  );
};

AppLayout.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default AppLayout;
