import styles from "./notifications.module.css";
import { Notification, NotificationContext } from "./NotificationContext";
import {
  Notification as KendoNotification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { useContext } from "react";

const Notifications = () => {
  const { notifications, removeNotification } = useContext(NotificationContext);

  return (
    <>
      {/* https://thrasio.atlassian.net/browse/RD-983 */}
      {/* eslint-disable no-inline-styles/no-inline-styles */}
      <NotificationGroup
        className={styles.notificationGroup}
        style={{ alignItems: "end" }}
      >
        {/* https://thrasio.atlassian.net/browse/RD-983 */}
        {/* eslint-enable no-inline-styles/no-inline-styles */}
        {notifications.map((notification: Notification, index) => {
          return (
            <KendoNotification
              key={index}
              type={{ style: notification.type, icon: true }}
              closable={true}
              onClose={() => {
                removeNotification(notification);
              }}
            >
              <span>{notification.text}</span>
            </KendoNotification>
          );
        })}
      </NotificationGroup>
    </>
  );
};

export default Notifications;
