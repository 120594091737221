import { Brand, CourierProvider } from "@trycourier/react-provider";
import { Toast } from "@trycourier/react-toast";
import { Inbox } from "@trycourier/react-inbox";
import { Loader } from "@progress/kendo-react-indicators";
import React from "react";
import { useMyNotificationConfig } from "@/api/graphql";

const theme = {
  container: {
    padding: "0px",
  },
  icon: {
    width: "32px",
    height: "32px",
    svg: {
      width: "32px",
      height: "32px",
    },
  },
};

const brand: Brand = {
  inapp: {
    disableCourierFooter: true,
    borderRadius: "10px",
    widgetBackground: {
      topColor: "#0d6efd",
      bottomColor: "#0d6efd",
    },
  },
  colors: {
    primary: "#1c2c49",
    secondary: "#c1b6dd",
    tertiary: "#e85178",
  },
};

const CourierInbox = () => {
  const { showCourier = false } = {};
  // https://thrasio.atlassian.net/browse/RD-980
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, data, loading: dataLoading } = useMyNotificationConfig();

  if (dataLoading) {
    return (
      <div>
        <Loader size="small" type="pulsing" />
      </div>
    );
  }

  const recipientId = data?.myNotificationConfig.recipientId;
  const hmacAuthSignature = data?.myNotificationConfig.hmacAuthSignature;

  return showCourier ? (
    <CourierProvider
      clientKey={process.env.NEXT_PUBLIC_COURIER_CLIENT_KEY}
      userId={recipientId}
      userSignature={hmacAuthSignature}
    >
      <Toast />
      <Inbox brand={brand} theme={theme} />
    </CourierProvider>
  ) : (
    <></>
  );
};

export default CourierInbox;
