import { ApolloLink } from "@apollo/client";
import { getOperationDefinition } from "@apollo/client/utilities";
import {
  DocumentNode,
  IntrospectionType,
  NamedTypeNode,
  TypeNode,
  VariableDefinitionNode,
  IntrospectionInputObjectType,
  IntrospectionInputType,
  IntrospectionInputTypeRef,
  IntrospectionNamedTypeRef,
} from "graphql";
import { isObjectLike, keyBy } from "@/components/util/object-util";
import { introspection } from "@/types/graphql";

type Variables = Record<string, any>;

const INTROSPECTION_TYPES: Record<string, IntrospectionType> = keyBy(
  introspection.__schema.types as IntrospectionType[],
  "name"
);

const isNamedTypeNode = (
  definition: TypeNode | VariableDefinitionNode
): definition is NamedTypeNode => {
  return definition.kind === "NamedType";
};

const isListTypeNode = (
  definition: TypeNode | VariableDefinitionNode
): definition is NamedTypeNode => {
  return definition.kind === "ListType";
};

const isIntrospectionNamedTypeRef = (
  inputTypeRef: IntrospectionInputTypeRef
): inputTypeRef is IntrospectionNamedTypeRef<IntrospectionInputType> => {
  return Boolean(inputTypeRef["name"]);
};

const isIntrospectionListTypeRef = (
  inputTypeRef: IntrospectionInputTypeRef
): inputTypeRef is IntrospectionNamedTypeRef<IntrospectionInputType> => {
  return inputTypeRef.kind === "LIST";
};

const isIntrospectionInputObjectType = (
  type: IntrospectionType
): type is IntrospectionInputObjectType => {
  return type.kind === "INPUT_OBJECT";
};

const getIntrospectionTypeFromTypeNode = (
  definition: TypeNode,
  isList = false
): [IntrospectionType, boolean] => {
  if (isNamedTypeNode(definition))
    return [INTROSPECTION_TYPES[definition.name.value], isList];

  return getIntrospectionTypeFromTypeNode(
    definition.type,
    isList || isListTypeNode(definition)
  );
};

const getIntrospectionTypeFromInputTypeRef = (
  inputTypeRef: IntrospectionInputTypeRef,
  isList = false
): [IntrospectionType, boolean] => {
  if (isIntrospectionNamedTypeRef(inputTypeRef))
    return [INTROSPECTION_TYPES[inputTypeRef.name], isList];

  return getIntrospectionTypeFromInputTypeRef(
    inputTypeRef.ofType,
    isList || isIntrospectionListTypeRef(inputTypeRef)
  );
};

const reduceVariables = <T>(
  items: readonly T[],
  getVarName: (item: T) => string,
  getIntrospectionType: (item: T) => [IntrospectionType, boolean],
  variables: any
) => {
  if (!variables || !isObjectLike(variables)) return variables;

  return items.reduce((vars: Variables, item: T) => {
    const varName = getVarName(item);
    const [type, isList] = getIntrospectionType(item);

    if (!(varName in variables)) return vars;

    return {
      ...vars,
      [varName]: extractInputVariables(type, variables[varName], isList),
    };
  }, {});
};

const extractInputVariables = (
  type: IntrospectionType,
  variables: any,
  isList: boolean
): Variables => {
  if (isList) {
    if (Array.isArray(variables))
      return variables.map((v) => extractInputVariables(type, v, false));
    else return variables;
  }

  if (!isIntrospectionInputObjectType(type)) return variables;

  return reduceVariables(
    type.inputFields,
    (inputField) => inputField.name,
    (inputField) => getIntrospectionTypeFromInputTypeRef(inputField.type),
    variables
  );
};

const cleanVariables = (query: DocumentNode, variables?: Variables) => {
  const { variableDefinitions = [] } = getOperationDefinition(query);

  return reduceVariables(
    variableDefinitions,
    (variableDefinition) => variableDefinition.variable.name.value,
    (variableDefinition) =>
      getIntrospectionTypeFromTypeNode(variableDefinition.type),
    variables
  );
};

export const cleanVariablesMiddleware = () =>
  new ApolloLink((operation, forward) => {
    operation.variables = cleanVariables(operation.query, operation.variables);
    return forward(operation);
  });
