import { uniq, compact } from "./object-util";

export const getUserDepartment = (groups) => {
  // matches the string from 'Team - ' to the first '/', ',' or end of string
  const matches = uniq(
    compact(
      groups.map((group) => {
        const match = group.match(/Team - (.*?)(,| \/|$)/);
        return match ? match[1] : undefined;
      })
    )
  );
  return matches.includes("Brand Operations")
    ? "Brand Operations"
    : matches.includes("Technology")
    ? "Technology"
    : matches.sort()[0];
};
