import { NavigationItem } from "@/types/navigation";

const navigation_items: NavigationItem[] = [
  { separator: true },
  {
    text: "Store Management",
    icon: "toolbox",
    flagName: "brandManagementPage",
    route: "/store-management",
  },
  { separator: true },
  {
    text: "Brand Management",
    icon: "toolbox",
    children: [
      {
        text: "TPID Correction Tool",
        route: "/tpid-correction-tool",
      },
      {
        text: "Brand Catalog",
        route: "/brand-catalog",
        flagName: "brandManagementPage",
      },
      {
        text: "Status Management",
        route: "/planning-status",
      },
    ],
  },
  { separator: true },
  {
    text: "Listing Management",
    icon: "map",
    flagName: "productCatalogBrandMapping",
    children: [
      {
        text: "List",
        route: "/listing-management/list",
      },
    ],
  },
  { separator: true },
  {
    text: "Marketing",
    icon: "toolbox",
    children: [
      {
        text: "Bid Management",
        route: "/marketing/bid-management",
        flagName: "marketingBidManagement",
      },
    ],
  },
  { separator: true },
  {
    text: "Affiliate Partners",
    icon: "user",
    flagName: "affiliatePartnerTools",
    oktaGroups: ["LaunchDarkly_affiliate-partner-data-ingestion"],
    children: [
      {
        text: "Publisher Sales Data",
        flagName: "affiliatePartnerDataIngestion",
        oktaGroups: ["LaunchDarkly_affiliate-partner-data-ingestion"],
        route: "/affiliate-partner-ingestion/publishers",
      },
      {
        text: "Influencer Sales Data",
        flagName: "affiliatePartnerDataIngestion",
        oktaGroups: ["LaunchDarkly_affiliate-partner-data-ingestion"],
        route: "/affiliate-partner-ingestion/influencers",
      },
    ],
  },
  { separator: true },
  {
    text: "Supply Chain Tools",
    icon: "shopping-cart",
    route: "/supply-chain/landing-page",
  },
  { separator: true },
];

if (process.env.NODE_ENV !== "production") {
  navigation_items.push({
    text: "Engine Core",
    children: [
      {
        text: "Calculation Log Dialog",
        route: "/engine-core/examples/SampleCalculationLogDialogPage",
      },
      {
        text: "Comment Indicator",
        route: "/engine-core/examples/SampleCommentIndicatorPage",
      },
      {
        text: "Calc Target Filters",
        route: "/engine-core/examples/SampleCalcTargetFilterPage",
      },
      {
        text: "Manual Override Dialog",
        route: "/engine-core/examples/SampleManualOverrideDialogPage",
      },
      { text: "Rule Sets", route: "/engine-core/rule-sets" },
      { text: "Segments", route: "/engine-core/segments" },
      { text: "Input Data Sources", route: "/engine-core/input-data-sources" },
    ],
  });
}

export { navigation_items };
