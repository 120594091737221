import React, { useState } from "react";

export enum NotificationType {
  None = "none",
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
}

export type Notification = {
  type: NotificationType;
  text: string;
};

export type NotificationContextData = {
  notifications: Notification[];
  setNotifications: (notification: Notification[]) => void;
  addNotification: (notification: Notification) => void;
  removeNotification: (notification: Notification) => void;
};

export const NotificationContext = React.createContext<NotificationContextData>(
  {
    notifications: [],
    setNotifications: () => {},
    addNotification: () => {},
    removeNotification: () => {},
  }
);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const addNotification = (notification: Notification) => {
    setNotifications([...notifications, notification]);
  };
  const removeNotification = (notificationToRemove: Notification) => {
    setNotifications(
      notifications.filter(
        (notification: Notification) => notification !== notificationToRemove
      )
    );
  };
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
