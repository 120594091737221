import * as React from "react";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";

/**
 * Conditionally enable dark mode. This component delays importing the darkreader library until after rendering
 * because it accesses the window variable at import time. This is not supported by next.js server side component
 * rendering, so we have to defer even loading the library until the browser.
 */
export const DarkMode = () => {
  // https://thrasio.atlassian.net/browse/RD-980
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDarkTheme, _] = useLocalStorage("darkTheme", false);
  useEffect(() => {
    import("darkreader").then((dr) => {
      if (isDarkTheme) {
        dr.enable({
          brightness: 90,
          contrast: 90,
          sepia: 10,
        });
      } else {
        dr.disable();
      }
    });
  });
  return <div></div>;
};
