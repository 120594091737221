/**
 * This file re-exports the graphql-codegen react-apollo functions from the __generated__ folder so that
 * import statements can be shortened to:
 *
 * `import { useGraphQLQuery } from @/api/graphql;`
 *
 * Files in `__generated__` only appear after `yarn setup-graphql` and `yarn generate` have been run.
 * If you run into any errors importing from this file, you can try to regenerate the files by
 * re-running the above 2 commands with their respective reset options
 *
 * @module
 */
export * from "@/api/__generated__/supergraph-react-apollo";
